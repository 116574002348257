import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import * as S from './InputFormatValue.styles';
import { NumberFormatBase } from 'react-number-format';

const InputFormatValue = ({
  disabled,
  value,
  width,
  height,
  required,
  name,
  control,
  formatType,
  variant,
  label,
  backcolor,
  color,
  labelcolor,
  labelfocuscolor,
  borderfocuscolor,
  error,
  spacelabelinput,
  decimalPlaces = 2
}) => {
  function currencyFormatter(value) {
    if (!Number(value)) return 'R$ 0,00';
    const amount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

    return `${amount}`;
  }

  // function percentFormatter(value) {
  //   if (!Number(value)) return '0,00 %';
  //   const amount = new Intl.NumberFormat('pt-BR', {
  //     style: 'percent',
  //     maximumFractionDigits: 2,
  //     minimumFractionDigits: 2,
  //   }).format(value / 10000);

  //   return `${amount}`;
  // }

function percentFormatter(value, decimalPlaces) {
  if (!Number(value)) return `0.${'0'.repeat(decimalPlaces)} %`;
  const divisor = Math.pow(10, decimalPlaces);
  // Calculate the percentage value with the desired number of decimal places
  const percentageValue = (value / divisor).toFixed(decimalPlaces);

  // Format the percentage string with proper separators
  const formattedPercentage = new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
  }).format(percentageValue) + ' %';

  return formattedPercentage;
}


  const withValueLimit = ({ floatValue }) => floatValue <= 9999999999;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <NumberFormatBase
          {...field}
          allowEmptyFormatting={true}
          width={width}
          height={height}
          disabled={disabled}
          format={
            formatType === 'percent'
              ? (value) => percentFormatter(value, decimalPlaces) 
              : currencyFormatter 
          }
          isAllowed={withValueLimit}
          customInput={S.Input}
          variant={variant}
          label={label}
          required={required}
          color={color}
          backcolor={backcolor}
          labelcolor={labelcolor}
          labelfocuscolor={labelfocuscolor}
          borderfocuscolor={borderfocuscolor}
          error={error}
          spacelabelinput={spacelabelinput}
        />
      )}
    />
  );
};

InputFormatValue.displayName = 'InputFormatValueComponent';
export default InputFormatValue;

InputFormatValue.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  formatType: PropTypes.bool,
  width: PropTypes.string,
  color: PropTypes.string,
  backcolor: PropTypes.string,
  labelcolor: PropTypes.string,
  labelfocuscolor: PropTypes.string,
  borderfocuscolor: PropTypes.string,
};
